import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { AppProvider } from "AppContext";
import * as Sentry from "@sentry/react";
import React from "react";
import "./i18n/config";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "assets/scss/simbalotto.scss";

import AdminLayout from "layouts/Admin.js";

const container = document.getElementById("root");
const root = createRoot(container);
const Login = require("views/Login/Login.js").default;

// Sentry.init({
//   dsn: "https://acda3fd7ff7e4b01b26dec640f39a8f3@o4504848175857664.ingest.sentry.io/4504848181690368",

//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
//   integrations: [new BrowserTracing(), new Sentry.Replay()],
//   tracesSampleRate: 1.0,
// });

root.render(
  <AppProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" render={(props) => <AdminLayout {...props} />} />
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </BrowserRouter>
  </AppProvider>
);
