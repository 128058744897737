import * as yup from "yup";
import Toast from "components/Toast";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

const Deposit = ({ player }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);

  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);
  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];
  const { t } = useTranslation();

  const withdrawalSchema = yup.object().shape({
    amount: yup
      .number()
      .typeError(t("user-profile.errors.required.amount"))
      .required(t("user-profile.errors.required.amount"))
      .min(10, t("user-profile.errors.min.amount")),
  });

  const {
    register: withdrawalRegister,
    handleSubmit: withdrawalSubmit,
    reset: withdrawalReset,
    trigger: withdrawalTrigger,
    getValues: withdrawalGetValues,
    formState: { errors: withdrawalErrors },
  } = useForm({
    resolver: yupResolver(withdrawalSchema),
  });

  const handleWithdrawalRequest = async (data) => {
    try {
      setLoading(true);
      await fetch("api/v1/users/withdraw", {
        method: "POST",
        body: new URLSearchParams(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          withdrawalReset();
          notify("success", data.message);
        })
        .catch((error) => notify("error", error.message))
        .finally(() => setLoading(false));
    } catch (error) {
      notify("error", error.message);
    }
  };

  return (
    <>
      {/* withdrawal input */}
      <div className="card bg-secondary">
        <div className="card-header border-0 bg-simbalotto-green">
          <h3 className="mb-0 text-capitalize text-dark">
            {t("user-profile.headings.withdraw")}
            {player?.wallet?.balance < 10 ? (
              <span className="text-danger">
                {" "}
                ({t("user-profile.notifications.withdraw-disabled")})
              </span>
            ) : null}
          </h3>
        </div>
        <div className="card-body">
          <form onSubmit={withdrawalSubmit(handleWithdrawalRequest)}>
            <h6 className="heading-small text-muted mb-4">
              {t("user-profile.headings.withdraw-info")}
            </h6>
            <div className="pl-lg-4">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label
                      className="form-control-label text-muted"
                      htmlFor="input-withdraw-amount"
                    >
                      {t("user-profile.fields.amount")}
                    </label>
                    <input
                      className="form-control form-control-alternative"
                      id="input-withdraw-amount"
                      placeholder="Amount"
                      type="number"
                      name="withdraw_amount"
                      {...withdrawalRegister("amount")}
                    />

                    {withdrawalErrors.amount && (
                      <span className="text-danger">
                        {withdrawalErrors.amount.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 mt-auto">
                  <div className="form-group">
                    <button
                      className="btn btn-simbalotto-orange text-dark"
                      type="submit"
                      onClick={async (e) => {
                        const result = await withdrawalTrigger("amount");
                        result && setAmount(withdrawalGetValues("amount"));
                        result && e.target.setAttribute("data-toggle", "modal");
                        result &&
                          e.target.setAttribute(
                            "data-target",
                            "#withdrawModal"
                          );
                      }}
                      disabled={
                        player?.wallet?.balance < 10
                          ? true
                          : loading
                          ? true
                          : false
                      }
                    >
                      <span className="btn-inner--icon text-dark">
                        <i className="fas fa-wallet" />
                      </span>
                      <span className="btn-inner--text text-dark">
                        {t("user-profile.buttons.withdraw")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* withdrawal modal */}
      <div
        className="modal fade"
        id="withdrawModal"
        tabIndex="-1"
        aria-labelledby="withdrawModalLabel"
        aria-hidden="true"
        bs-backdrop="static"
        bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-capitalize"
                id="withdrawModalCenterTitle"
              >
                {t("user-profile.withdrawal.title")}
              </h5>
              <span
                aria-hidden="true"
                className="close fa-2x"
                data-dismiss="modal"
                aria-label="Close"
                role="button"
              >
                &times;
              </span>
            </div>
            <div className="modal-body">
              <div className="row">
                <div
                  className="col-6 mb-2"
                  {...(player.kyc_status === null ||
                  player.kyc_status === "rejected"
                    ? {
                        "data-toggle": "modal",
                        "data-target": "#kycModal",
                      }
                    : player.kyc_status === "submitted"
                    ? {
                        "data-toggle": "modal",
                        "data-target": "#kycPendingModal",
                      }
                    : {
                        "data-toggle": "modal",
                        "data-target": "#withdrawalModal",
                      })}
                  role="button"
                >
                  <div className="card bg-secondary shadow">
                    <div className="card-body bg-white border-0">
                      <div className="row align-items-center">
                        <div className="col-12 text-center">
                          <i className="fas fa-university fa-3x text-primary" />
                        </div>
                        <div className="col-12">
                          <h3 className="mb-0 text-center">
                            {t("user-profile.withdrawal.bank")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 mb-2" role="button">
                  <div className="card bg-secondary shadow">
                    <div className="card-body bg-white border-0">
                      <div className="row align-items-center">
                        <div className="col-12 text-center">
                          <i className="fa-brands fa-bitcoin fa-3x text-primary" />
                        </div>
                        <div className="col-12">
                          <h3 className="mb-0 text-center">
                            {t("user-profile.withdrawal.cryptocurrency")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deposit;
