import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useApp } from "../../AppContext";

const Header = () => {
  const [totalTicket, setTotalTicket] = useState([]);
  const [upcomingTicket, setUpcomingTicket] = useState([]);
  const [pastTicket, setPastTicket] = useState([]);
  const { t } = useTranslation();
  const { balance, token, refetchBalance } = useApp();

  useEffect(() => {
    async function fetchData() {
      try {
        await fetch("/api/v1/userLotteriesCount", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setTotalTicket(result.total);
            setUpcomingTicket(result.pending);
            setPastTicket(result.drawn);
          });
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
    refetchBalance();
  }, [refetchBalance, token]);

  return (
    <div className="row mb-3">
      <div className="col-xl-2 col-lg-6">
        <div className="card bg-simbalotto-green card-stats mb-4 mb-xl-0">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <h5 className="text-uppercase text-dark mb-0">
                  {t("dashboard.my-balance")}
                </h5>
                <span className="h2 font-weight-bold mb-0 text-white">
                  {balance.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-lg-6">
        <div className="card bg-simbalotto-green card-stats mb-4 mb-xl-0">
          <Link to="/my-tickets">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h5 className="text-uppercase text-dark mb-0">
                    {t("dashboard.all-tickets")}
                  </h5>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {totalTicket}
                  </span>
                </div>
                <div className="col-auto">
                  <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i className="fas fa-gamepad" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="col-xl-3 col-lg-6">
        <div className="card bg-simbalotto-green card-stats mb-4 mb-xl-0">
          <Link to="/my-tickets">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h5 className="text-uppercase text-dark mb-0">
                    {t("dashboard.upcoming-draw-lines")}
                  </h5>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {upcomingTicket}
                  </span>
                </div>
                <div className="col-auto">
                  <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                    <i className="fas fa-puzzle-piece" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="col-xl-2 col-lg-6">
        <div className="card bg-simbalotto-green card-stats mb-4 mb-xl-0">
          <Link to="/my-tickets">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h5 className="text-uppercase text-dark mb-0">
                    {t("dashboard.past-draw-lines")}
                  </h5>
                  <span className="h2 font-weight-bold mb-0 text-white">
                    {pastTicket}
                  </span>
                </div>
                <div className="col-auto">
                  <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                    <i className="fas fa-trophy" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="col-xl-2 col-lg-6">
        <div className="card bg-simbalotto-green card-stats mb-4 mb-xl-0">
          <Link to="/transactions">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h5 className="text-uppercase text-dark mb-0">
                    {t("dashboard.my-transactions")}
                  </h5>
                  <span className="h2 font-weight-bold mb-0"></span>
                </div>
                <div className="col-auto">
                  <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                    <i className="fas fa-money-bill-transfer" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
