import React, { useState, useEffect, useRef, useCallback } from "react";
import useDocumentTitle from "../../useDocumentTitle";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import ViewTicketBody from "./ViewTicketBody";
import { useParams } from "react-router-dom";
import Toast from "components/Toast";

const ViewTicket = () => {
  const editorRef = useRef(null);
  const [ticket, setTicket] = useState([]);
  const { id } = useParams();
  const { t } = useTranslation();
  useDocumentTitle(
    `${ticket?.subject ? ticket.subject : t("tickets.view-ticket")} | ${t(
      "site-name"
    )}`
  );
  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);

  const fetchTicket = useCallback(async () => {
    try {
      await fetch(`/api/v1/tickets/show/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          setTicket(data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (e) {
      console.error("Error fetching api data", e);
    }
  }, [id, token]);

  useEffect(() => {
    fetchTicket();
  }, [fetchTicket]);

  const handleTicketReply = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("description", editorRef.current.getContent());
    const data = Object.fromEntries(formData.entries());
    fetch(`/api/v1/tickets/${id}/reply`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        notify("success", data.message);
        editorRef.current.setContent("");
        fetchTicket();
      })
      .catch((error) => notify("error", error.message));
  };

  const handleTicketClose = (e) => {
    e.preventDefault();
    try {
      fetch(`/api/v1/tickets/${id}/closeTicket`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          notify("success", data.message);
          fetchTicket();
        })
        .catch((error) => notify("error", error.message));
    } catch (error) {
      notify("error", error.message);
    }
  };

  const image_upload_handler = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", "/api/v1/tickets/uploadImage");
      xhr.setRequestHeader("Authorization", "Bearer " + token);

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status === 403) {
          reject({ message: "HTTP Error: " + xhr.status, remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);

        if (!json || typeof json.location != "string") {
          reject("Invalid JSON: " + xhr.responseText);
          return;
        }

        resolve(json.location);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    });

  return (
    <>
      {!ticket ? (
        <div className="container col-12 bg-simbalotto-green min-vh-100" fluid>
          <div className="row justify-content-center">
            <div id="preloader2"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="row mb-4">
            <div className="col-xl-12 px-0">
              <div className="card bg-simbalotto-green shadow">
                <div className="card-header bg-white border-0">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h3 className="mb-0">
                        {t("tickets.title-singular")} #{ticket.id} -{" "}
                        {ticket.subject}
                      </h3>
                    </div>
                    <div className="col-2 text-right">
                      {ticket.status !== "closed" ? (
                        <form onSubmit={handleTicketClose}>
                          <button
                            type="submit"
                            className="btn btn-md btn-warning"
                          >
                            {t("tickets.close")}
                          </button>
                        </form>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-2 text-right">
                      <span
                        className={`badge badge-dot mr-4 ${
                          ticket.status === "open"
                            ? "badge-success"
                            : ticket.status === "closed"
                            ? "badge-danger"
                            : "badge-info"
                        }`}
                      >
                        <i
                          className={`${
                            ticket.status === "open"
                              ? "bg-success"
                              : ticket.status === "closed"
                              ? "bg-danger"
                              : "bg-info"
                          }`}
                        />
                        {ticket.status}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12">
              <div className="accordion" id="replyAccordion">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    {ticket.status === "closed" ? (
                      <h3 className="mb-0">{t("tickets.closed-message")}</h3>
                    ) : (
                      ""
                    )}
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left text-capitalize"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        {t("buttons.reply")}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#replyAccordion"
                  >
                    <div className="card-body">
                      <form onSubmit={handleTicketReply}>
                        <div className="row">
                          <div className="col-12">
                            <Editor
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              apiKey="zv2m76wsswzj7co4829kn3eio92x327fbc0gwct5fryymny0"
                              init={{
                                min_height: 300,
                                menubar: false,
                                plugins: [
                                  "advlist",
                                  "autolink",
                                  "lists",
                                  "link",
                                  "image",
                                  "charmap",
                                  "preview",
                                  "anchor",
                                  "searchreplace",
                                  "visualblocks",
                                  "code",
                                  "fullscreen",
                                  "insertdatetime",
                                  "media",
                                  "table",
                                  "code",
                                  "help",
                                  "wordcount",
                                  "autoresize",
                                ],
                                toolbar:
                                  "undo redo | formatselect | " +
                                  "bold italic backcolor | alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent | " +
                                  "removeformat | image | help",
                                a11y_advanced_options: true,
                                image_title: true,
                                automatic_uploads: true,
                                file_picker_types: "file image media",
                                images_upload_handler: image_upload_handler,
                                image_class_list: [
                                  { title: "Width", value: "mw-100" },
                                ],
                                block_unsupported_drop: true,
                                default_link_target: "_blank",
                                file_picker_callback: function (
                                  cb,
                                  value,
                                  meta
                                ) {
                                  var input = document.createElement("input");
                                  input.setAttribute("type", "file");
                                  input.setAttribute("accept", "image/*");
                                  input.onchange = function () {
                                    var file = this.files[0];

                                    var reader = new FileReader();
                                    reader.onload = function () {
                                      var id =
                                        "supportTicket" + new Date().getTime();
                                      var blobCache =
                                        editorRef.current.editorUpload
                                          .blobCache;
                                      var base64 = reader.result.split(",")[1];
                                      var blobInfo = blobCache.create(
                                        id,
                                        file,
                                        base64
                                      );
                                      blobCache.add(blobInfo);

                                      cb(blobInfo.blobUri(), {
                                        title: file.name,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  };
                                  input.click();
                                },
                              }}
                            />
                          </div>
                          <div className="col-12">
                            <button
                              type="submit"
                              className="btn btn-primary mt-3"
                            >
                              {t("buttons.submit")}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-auto">
            {ticket.support_ticket_replies?.map((ticket, index) => (
              <ViewTicketBody ticket={ticket} key={index} />
            ))}
            <ViewTicketBody ticket={ticket} key={ticket.id} />
          </div>
        </>
      )}
    </>
  );
};

export default ViewTicket;
