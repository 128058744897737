import parse from "html-react-parser";

const ViewTicketBody = ({ ticket }) => {
  return (
    <div className="col-xl-12 mb-2">
      <div className="card shadow">
        <div className="card-header bg-white border-0 pl-1">
          <div className="row">
            <div className="col-8 d-flex flex-row" xs="8">
              <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                <i className="fas fa-user" />
              </div>
              <div className="pl-2">
                <span>
                  {ticket.users
                    ? "ME"
                    : ticket.operator_users
                    ? ticket.users?.first_name
                    : ""}{" "}
                  {ticket.users
                    ? null
                    : ticket.operator_users
                    ? ticket.users?.last_name
                    : ""}
                </span>
                <br />
                <span>{ticket.users ? ticket.users?.user_email : ""}</span>
              </div>
            </div>
            <div className="col-4 text-right">
              {ticket.opened_at && (
                <span className="text-muted">
                  {new Date(ticket.opened_at).toLocaleString()}
                </span>
              )}
              {ticket.reply_at && (
                <span className="text-muted">
                  {new Date(ticket.reply_at).toLocaleString()}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row pl-4">
            <div>{ticket.description ? parse(ticket.description) : ""}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTicketBody;
