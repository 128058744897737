import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

const DatePickerCustom = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Date picker"
        value={props.getFilterValue()}
        onChange={(newValue) => {
          if (newValue instanceof Date && !isNaN(newValue)) {
            props.setFilterValue(newValue);
          } else {
            props.setFilterValue(undefined);
          }
        }}
        textField={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default DatePickerCustom;
